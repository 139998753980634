<template>
  <v-navigation-drawer
    class="hidden-sm-and-down"
    v-model="sidebar"
    height="100%"
    width="325"
    mini-variant
    expand-on-hover
    absolute
    permanent
    style="
      z-index: 2;
      position: fixed;
      height: 100vh;
      top: 70px;
      max-height: calc(100% + 0px);
      transform: translateX(0%);
    "
  >
    <v-card class="mx-auto" height="100%" elevation="0">
      <v-list>
        <!-- EMAIL -->

        <v-list-item color="#df6464" v-for="item in email" :key="item.en" :to="item.path">
          <v-icon>{{ item.icon }}</v-icon>
          <v-list-item-title>
            {{ language == "en" ? item.en : item.ja }}
          </v-list-item-title>
        </v-list-item>

        <!-- <v-list-item @click="directToAnotherPage">
          <v-icon>mdi-alpha-g-circle</v-icon>
          <v-list-item-title>{{
            language == "en" ? "GC Portal System" : "GCポータルシステム"
          }}</v-list-item-title>
        </v-list-item> -->
        <v-list-group prepend-icon="mdi-email" color="#df6464" :value="true" no-action>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{
                language == "en" ? "Email" : "Eメール"
              }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            color="#df6464"
            v-for="item in inbox"
            :key="item.en"
            :to="item.path"
          >
            <v-icon>{{ item.icon }}</v-icon>
            <v-list-item-title>
              {{ language == "en" ? item.en : item.ja }}
            </v-list-item-title>
          </v-list-item>
        </v-list-group>
        <!-- ACCOUNT -->
        <!-- v-if="type != 'Inquiry' || userEmail == 's-fukaya@hrd-s.com'" -->
        <v-list-group
          v-if="
            Position == 'Supervisor' ||
            Position == 'Assistant Supervisor' ||
            userEmail == 's-fukaya@hrd-s.com' ||
            EmployeeCode == '00000' ||
            userEmail == 'sk-chouki_yuryo@ichijo.co.jp' ||
            userEmail == 'sk-kouzou@ichijo.co.jp'
          "
          color="#df6464"
          prepend-icon="mdi-account"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-title>{{
              language == "en" ? "Master Settings" : "マスター設定"
            }}</v-list-item-title>
          </template>
          <v-list-item
            v-if="
              EmployeeCode == '00000' ||
              userEmail == 's-fukaya@hrd-s.com' ||
              userEmail == 'sk-chouki_yuryo@ichijo.co.jp' ||
              userEmail == 'sk-kouzou@ichijo.co.jp'
            "
            color="#df6464"
            to="/category"
          >
            <v-icon>mdi-account-key</v-icon>
            <v-list-item-title>
              {{
                language == "en" ? "Inquiry Master" : "問い合わせ担当者マスター"
              }}</v-list-item-title
            ></v-list-item
          >
          <v-list-item color="#df6464" to="/mastermaintenance">
            <v-icon>mdi-key-variant</v-icon>
            <v-list-item-title>
              {{
                language == "en" ? "User Master" : "ユーザーマスター"
              }}</v-list-item-title
            ></v-list-item
          >
        </v-list-group>
        <v-list-item
          color="#df6464"
          v-for="item in dataExtraction"
          :key="item.en"
          :to="item.path"
          @click="openInquirySum()"
        >
          <v-icon>{{ item.icon }}</v-icon>
          <v-list-item-title>{{
            language == "en" ? item.en : item.ja
          }}</v-list-item-title>
        </v-list-item>
        <!-- <v-list-item color="#df6464" v-for="item in outbox" :key="item.en" :to="item.path">
          <v-icon>{{ item.icon }}</v-icon>
          <v-list-item-title
            v-text="language == 'en' ? item.en : item.ja"
          ></v-list-item-title>
        </v-list-item> -->
      </v-list>
    </v-card>
    <v-dialog v-model="openDialog" width="20%">
      <v-card>
        <v-card-title style="background-color: #df6464; color: #fff">
          <v-icon>mdi-chart-bar</v-icon>
          Inquiry Summary</v-card-title
        >
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field
                outlined
                dense
                :items="listOfDeparment"
                v-model="selectDept"
                label="Department"
                @change="filterbySection(selectDept)"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                outlined
                dense
                :items="listOfSection"
                v-model="selectSect"
                label="Section"
                @change="filterbyTeam(selectSect)"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                label="Team"
                outlined
                dense
                :items="listOfTeam"
                v-model="selectTeam"
              />
              <!-- @change="filterbyJobKind()" -->
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-autocomplete
                label="Job Kind"
                outlined
                dense
                :items="listOfJobKind"
                v-model="selectJobKind"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                v-if="selectDept == 'All Department'"
                block
                outlined
                dense
                color="#DF6464"
                rounded
                :to="{
                  name: 'InquirySummaryAllDeparment',
                  params: {
                    department: selectDept,
                    section: selectSect,
                    team: selectTeam,
                  },
                }"
                @click="getDepartmentforExtraction()"
              >
                Enter All Department
              </v-btn>
              <v-btn
                v-else
                block
                outlined
                dense
                color="#DF6464"
                rounded
                :to="{
                  name: 'InquirySummary',
                  params: {
                    department: selectDept,
                    section: selectSect,
                    team: selectTeam,
                  },
                }"
                @click="getDepartmentforExtraction()"
                >Enter</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-navigation-drawer>
</template>
<script>
import store from "@/store";
import md5 from "md5";
import keys from "../../functions/alert";
import axios from "axios";
// import { Organizations } from 'aws-sdk';
export default {
  data() {
    return {
      selectDept: store.state.userinfo.Department,
      selectSect: store.state.userinfo.Section,
      selectTeam: store.state.userinfo.Team,
      selectJobKind: "",
      listOfJobKind: [],
      listOfDeparment: [],
      listOfSection: [],
      listOfTeam: [],
      openDialog: false,
      sidebar: false,
      authenticated: true,
      name: "",
      EmployeeCode: "",
      type: store.state.userinfo.Type,
      Position: store.state.userinfo.Position,
      userEmail: store.state.userinfo.Email,
      team: store.state.userinfo.Team,
      section: store.state.userinfo.Section,
      department: store.state.userinfo.Department,
      // outbox: [
      //   {
      //     en: "HRD Process",
      //     ja: "HRD Process",
      //     path: "/outbox",
      //     icon: "mdi-email-newsletter"
      //   }
      // ],

      dataExtraction: [
        {
          en: "Inquiry Summary",
          ja: "お問い合わせ内容",
          // path: "/extraction",
          icon: "mdi-chart-bar",
        },
      ],
      inbox: [
        {
          en: "Inbox",
          ja: "受信トレイ",
          path: "/received",
          icon: "mdi-email-arrow-left",
        },
        {
          en: "Inbox (CC)",
          ja: "受信トレイ（CC）",
          path: "/SubHandle",
          icon: "mdi-email-variant",
        },

        {
          en: "Outbox (inquiring)",
          // ja: "送信トレイ（送信済みトレイ）",
          ja: "送信済みトレイ",
          path: "/inbox",
          icon: "mdi-inbox",
        },
        // {
        //   en: "All Emails",
        //   ja: "全てのメール",
        //   path: "/AllEmails",
        //   icon: "mdi-email-multiple",
        // },
        // {
        //   en: "HRD Process",
        //   ja: "HRD Process",
        //   path: "/outbox",
        //   icon: "mdi-email-newsletter",
        // },

        {
          en: "Save Draft",
          ja: "下書き保存",
          path: "/Draft",
          icon: "mdi-content-save-edit",
        },
        {
          en: "Mailbox Management",
          // ja: "すべて受信トレイ部門",
          ja: "メールボックス管理",
          path: "/MailboxManagement",
          icon: "mdi-email-search-outline",
        },
        {
          en: "All Inbox Department",
          // ja: "すべて受信トレイ部門",
          ja: "全てのメール",
          path: "/AllInboxDepartment",
          icon: "mdi-folder-home-outline",
        },
      ],
      email: [
        {
          en: "Contact HRD",
          ja: "HRDへの問い合わせ",
          path: "/composed",
          icon: "mdi-clipboard-text",
        },
        {
          en: "Correction Request",
          // ja: "訂正依頼",
          ja: "HRDからの問い合わせ",
          path: "/correction",
          icon: "mdi-book-check",
        },
        {
          en: "Admin User",
          ja: "管理者ユーザー",
          path: "/adminUser",
          icon: "mdi-account-lock",
        },
      ],
    };
  },
  computed: {
    //  checkRoutes() {
    //   // alert("TEST")
    //   if (this.routerPath == "/InquirySummary") return "";
    //   else return "InquirySummary/";
    // },
    language() {
      return this.$i18n.locale;
    },
  },
  created() {
    // CHECK USER TYPE
    if (this.type == "Both" && store.state.userinfo.isAdmin) {
      this.inbox;
      // console.log("ADMIN UERSSS");
    } else if (store.state.userinfo.Email == "s-fukaya@hrd-s.com") {
      this.email.splice(1,1)
      this.inbox;
      // this.inbox.splice(3, 1);
      // this.inbox.splice(3, 1);
    } else if (store.state.userinfo.Email == "sk-chouki_yuryo@ichijo.co.jp") {
      this.inbox.splice(3, 1);
    } else if (store.state.userinfo.Email == "sk-kouzou@ichijo.co.jp") {
      this.inbox.splice(3, 1);
    } else if (
      store.state.userinfo.FullName == "ADMIN" &&
      store.state.userinfo.Email == "imtrial@hrd-s.com"
    )
      this.inbox.splice(3, 1);
    else if (this.type == "Inquiry") {
      this.email.splice(1, 2);
      this.inbox.splice(5, 1);
      this.dataExtraction.splice(0, 1);
    } else if (
      (this.type == "Correction" && this.Position == "Supervisor") ||
      this.Position == "Senior Supervisor" ||
      this.Position == "Deputy Manager"
    ) {
      this.email.splice(0, 1);

      this.inbox.splice(3, 1);
    } else if (this.type == "Correction" && this.section != "Plan Administration") {
      // this.email = this.email.slice(this.email.length - 1);
      this.email.splice(0, 1);
      this.email.splice(1, 1);
      this.inbox.splice(3, 1);
      this.dataExtraction.splice(1, 1);
      // this.dataExtraction.splice(0, 1);
    } else if (
      this.type == "Correction" &&
      this.department == "CAD Planning 1" &&
      this.section == "Plan Administration"
    ) {
      // this.email = this.email.slice(this.email.length - 1);
      // console.log(this.outbox)
      this.email.splice(0, 1);
      this.email.splice(1, 1);
      this.inbox.splice(5, 1);
      this.inbox.splice(3, 1);
      this.dataExtraction.splice(1, 1);
      // this.dataExtraction.splice(0, 1);
    }
    // CHECK TYPE OF USER
    if (store.state.userinfo.EmployeeNumber) {
      // if (this.$EmployeeCode.length == 5) {
      //   this.email.splice(0, 1);
      // }
      this.EmployeeCode = store.state.userinfo.EmployeeNumber;
      this.name =
        store.state.userinfo.FirstNameEng + " " + store.state.userinfo.LastNameEng;
    } else {
      this.name = store.state.userinfo.FullName;
      this.EmployeeCode = store.state.userinfo.EmployeeCode;
    }

    // if (this.EmployeeCode != "0417") this.email.splice(1, 1);
  },

  methods: {
    getDepartmentforExtraction() {
      if (this.selectDept == "All Department" && !this.selectSect && !this.selectTeam) {
        let b = {
          department: "All Department",
          section: "All Department",
          team: "All Department",
        };
        this.$store.commit("Organization", b);
        this.openDialog = false;
        process.env.NODE_ENV == "development"
          ? window.open(
              `http://sys-com_svr/hrdinquiry/${
                this.type == "Correction"
                  ? "InquirySummaryAllDeparment"
                  : "InquirySummaryAllDeparment"
              }`,
              "_self"
            )
          : window.open(
              `http://sys-com_svr/hrdinquiry/${
                this.type == "Correction"
                  ? "InquirySummaryAllDeparment"
                  : "InquirySummaryAllDeparment"
              }`,
              "_self"
            )
          ? window.open(
              `http://sys-com_svr/hrdinquiry/${
                this.type == "Correction"
                  ? "InquirySummaryAllDeparment"
                  : "InquirySummaryAllDeparment"
              }`,
              "_self"
            )
          : window.open(
              `http://inquirytestbucket.hrd-s.com.s3-website-ap-southeast-1.amazonaws.com/${
                this.type == "Correction"
                  ? "InquirySummaryAllDeparment"
                  : "InquirySummaryAllDeparment"
              }`,
              "_self"
            );
      } else {
        let a = {
          department: this.selectDept,
          section: this.selectSect,
          team: this.selectTeam,
          jobKind: this.selectJobKind,
        };
        this.$store.commit("Organization", a);
        this.openDialog = false;
        process.env.NODE_ENV == "development"
          ? window.open(
              `http://${window.location.host}/${
                this.type == "Correction" ? "InquirySummary" : "InquirySummary"
              }`,
              "_self"
            )
          : window.open(
              `https://inquiry.hrd-s.com/${
                this.type == "Correction" ? "InquirySummary" : "InquirySummary"
              }`,
              "_self"
            )
          ? window.open(
              `http://${window.location.host}/${
                this.type == "Correction" ? "InquirySummary" : "InquirySummary"
              }`,
              "_self"
            )
          : window.open(
              `http://inquirytestbucket.hrd-s.com.s3-website-ap-southeast-1.amazonaws.com/${
                this.type == "Correction" ? "InquirySummary" : "InquirySummary"
              }`,
              "_self"
            );
        // window.location.reload();
      }
    },
    //**************************************//
    // API DEPARTMENT   //
    //**************************************//
    filterbyJobKind() {
      axios.get(`${keys.auth.BaseUrl}/getdepartment`, keys.auth.apiKey).then((res) => {
        // console.log(res.data);
        let filterJobKind = res.data.filter((rec) => {
          if (this.selectTeam == rec.Team) {
            return rec.Job;
          }
        });
        let convertedArr = JSON.parse(filterJobKind[0].Job);
        this.chooseJobKind(convertedArr);
      });
    },
    chooseJobKind(val) {
      for (let i = 0; i < val.length; i++) {
        const element = val[i];
        // console.log(element, "527");
        axios
          .get(`${keys.auth.BaseUrl}/getdepartment/${element}`, keys.auth.apiKey)
          .then((res) => {
            // console.log(res.data, "530");
            let chooseJBK = res.data.map((rec) => {
              return `${rec.Code}/${rec.EnglishName}/${rec.JapaneseName}`;
              // return rec.Code;
            });
            // let empArrJK = [];
            // empArrJK.push(chooseJBK[0]);
            if (store.state.userinfo.EmployeeCode == "04874") {
              let detailobj = [
                {
                  Code: "SUI103",
                  JapaneseName: "SUISEN",
                  EnglishName: "SUISEN",
                  Department: "SUIDOU",
                },
              ];
              let choose = detailobj.map((rec) => {
                return `${rec.Code}/${rec.EnglishName}/${rec.JapaneseName}`;
                // return rec.Code;
              });
              // console.log(detailobj);
              this.listOfJobKind.push(choose[0]);
            } else {
              this.listOfJobKind.push(chooseJBK[0]);
            }
            // console.log(chooseJBK[0]);
          });
      }
      // axios
      //   .get(`${keys.auth.BaseUrl}/getdepartment/${this.selectDept}`, keys.auth.apiKey)
      //   .then((res) => {
      //     for (let i = 0; i < res.data.length; i++) {
      //       const element = res.data[i].Code;
      //       console.log(val.includes(element));
      //     }
      //   });
    },
    filterbyTeam(val) {
      axios.get(`${keys.auth.BaseUrl}/getdepartment`, keys.auth.apiKey).then((res) => {
        this.nagetSect = val;
        let filterTeam = res.data.filter((rec) => {
          if (this.nagetSect == rec.Section) {
            return rec.Team;
          }
        });
        let mapTeam = filterTeam.map((rec) => {
          return rec.Team;
        });
        this.listOfTeam = Object.assign(mapTeam);
      });
    },
    filterbySection(val) {
      axios.get(`${keys.auth.BaseUrl}/getdepartment`, keys.auth.apiKey).then((res) => {
        this.nagetDept = val;
        let filterSect = res.data.filter((rec) => {
          if (this.nagetDept == rec.Department) {
            return rec.Section;
          }
        });
        let mapSect = filterSect.map((rec) => {
          return rec.Section;
        });
        this.listOfSection = Object.assign(mapSect);
      });
    },
    listofDept() {
      if (
        store.state.userinfo.FullName == "MA. CRISTINA A. CASAJEROS" &&
        store.state.userinfo.EmployeeCode == "07439"
      ) {
        this.selectDept = "CAD Planning 1,CAD Planning 2,CAD Planning 3";
        this.selectSect = "";
        this.selectTeam = "";
      }
      axios.get(`${keys.auth.BaseUrl}/getdepartment`, keys.auth.apiKey).then((res) => {
        let filterDep = res.data.map((rec) => {
          return rec.Department;
        });
        filterDep.splice(0, 0, "All Department");
        // console.log(filterDep);
        this.listOfDeparment = Object.assign(filterDep);
      });
    },

    //**************************************//
    // PASS INFORMATION IN GC PORTAL HREF   //
    //**************************************//
    openInquirySum() {
      this.openDialog = true;
      // this.selectDept = "";
      // this.selectSect = "";
      // this.selectTeam = "";
      // this.selectJobKind = "";
      // this.listOfJobKind = [];
    },
    directToAnotherPage() {
      const email = store.state.userinfo.Email;
      const id = store.state.userinfo.EmployeeCode;

      window.open(
        // `https://gcportal.hrd-s.com?index=${md5(email)}&id=${id}`,
        `https://gcportal.hrd-s.com/ja/SignIn?index=${md5(email)}&id=${md5(id)}`,
        `_blank`
      );
    },
  },

  mounted() {
    this.listofDept();
    this.filterbyJobKind();
    // console.log(this.selectDept, this.selectSect, this.selectTeam);
  },
};
</script>

<style></style>
